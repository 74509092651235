import React from "react";
import { graphql } from "gatsby";

// Component imports
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image";
import { motion } from "framer-motion";
import SEO from "../components/SEO";

import moment from "moment/moment";

import Section from "../components/Section";

import { InView } from "react-intersection-observer";
import FeaturedPost from "../components/FeaturedPost";
import Text from "../components/Text";

import useWindowSize from "../components/hooks/useWindowSize";

const Post = ({ data }: any) => {
    const { width } = useWindowSize();
    console.log(data);
    const post = data.ghostPost;

    const relatedPosts = data.relatedPosts.edges.filter((relatedPost: any) => {
        if (relatedPost.node.slug !== post.slug) return relatedPost;
    });

    console.log(relatedPosts);

    if (relatedPosts.length > 3) {
        relatedPosts.pop(4);
    }

    const pageBg = [
        `linear-gradient(hsla(0, 0%, 6%, .95), hsla(0, 0%, 6%, .95))`,

        data.page_bg.childImageSharp.fluid,
    ];

    return (
        <Layout>
            <SEO
                title={post.title}
                article={true}
                articleAuthor={post.authors}
                description={post.excerpt}
                image={post.feature_image}
                imageAlt={post.excerpt}
                articleDatetime={post.created_at}
                articleModifiedTime={post.updated_at}
            />
            <article>
                {/* Header */}

                <BackgroundImage
                    fluid={data.background.childImageSharp.fluid}
                    className="z-10"
                >
                    <section className="h-newsHeaderSmall lg:h-postHeader md:p-8 container relative p-4 mx-auto">
                        <motion.div
                            initial={{ y: 500, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                damping: 20,
                                type: "spring",
                                mass: 1.5,
                            }}
                            className="md:px-4 lg:px-12 flex items-center w-full h-full"
                        >
                            <img
                                src={post.feature_image}
                                alt={post.title}
                                className="md:transform md:translate-y-16 object-cover w-full max-h-full"
                            />
                        </motion.div>

                        {/* Overlay */}
                        <div className="lg:w-9/12 lg:translate-x-40 lg:translate-y-16 lg:h-postDivOverlayLarge md:px-6 h-postDivOverlay absolute inset-0 w-full px-2 transform translate-y-4">
                            <motion.div
                                initial={{ scale: 5, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 1,
                                    delay: 0.5,
                                    bounce: 0,
                                }}
                                className=" flex items-end w-full h-full border-2 border-gray-500"
                            >
                                <div className="w-full">
                                    <div className="lg:p-8 p-4">
                                        <h1 className="mb-2 text-2xl font-semibold">
                                            {post.title}
                                        </h1>
                                        <p className="my-0">{post.excerpt}</p>
                                    </div>
                                    <div className="flex w-full">
                                        <div className="lg:p-8 flex items-center flex-1 p-3 border-t-2 border-r-2 border-gray-500">
                                            <h3 className="mb-0 text-xs font-semibold text-gray-500 uppercase">
                                                <span className="mr-2">
                                                    Date:
                                                </span>{" "}
                                                <span>
                                                    {" "}
                                                    {moment(
                                                        post.created_at
                                                    ).format("MMMM Do, YYYY")}
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="lg:p-8 flex items-center flex-1 p-3 border-t-2 border-gray-500">
                                            <h3 className="mb-0 text-xs font-semibold text-gray-500 uppercase">
                                                <span className="mr-2">
                                                    {post.authors.length > 1
                                                        ? "Authors:"
                                                        : "Author:"}
                                                </span>{" "}
                                                <span>
                                                    {post.authors.map(
                                                        (
                                                            {
                                                                name,
                                                            }: { name: string },
                                                            i: number
                                                        ) => (
                                                            <span key={name}>
                                                                {name}
                                                                {post.authors
                                                                    .length > 1
                                                                    ? i++ ===
                                                                      post
                                                                          .authors
                                                                          .length
                                                                        ? ""
                                                                        : ", "
                                                                    : ""}
                                                            </span>
                                                        )
                                                    )}
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </section>
                </BackgroundImage>
                <div className="bg-[#1a1a1a]">
                    <Section>
                        <div
                            dangerouslySetInnerHTML={{ __html: post.html }}
                            className="pt-postContent"
                        ></div>
                    </Section>
                </div>
                {/* <BackgroundImage
                    fluid={pageBg}
                    className=" bg-repeat"
                ></BackgroundImage> */}
            </article>
            <div className="bg-gray-700">
                {relatedPosts.length >= 1 && (
                    <Section>
                        <Text
                            variant="p"
                            className="lg:text-sm xxl:transform xxl:w-56 xxl:-rotate-90 xxl:-translate-x-40 xxl:translate-y-12 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                        >
                            - Related Articles -
                        </Text>
                        <div className="lg:flex-row lg:items-center lg:space-y-0 lg:space-x-4 flex flex-col space-y-12">
                            {relatedPosts.map((relatedPost: any, i: number) => {
                                return (
                                    <InView triggerOnce threshold={0.2}>
                                        {({ inView, ref }) => (
                                            <div ref={ref} className="lg:w-1/3">
                                                <motion.div
                                                    initial={{
                                                        y: 50,
                                                        opacity: 0,
                                                    }}
                                                    animate={
                                                        inView && {
                                                            y: 0,
                                                            opacity: 1,
                                                        }
                                                    }
                                                    transition={
                                                        width >= 1024 && {
                                                            delay: i * 0.25,
                                                        }
                                                    }
                                                    key={`article-${i}`}
                                                >
                                                    <FeaturedPost
                                                        desc={
                                                            relatedPost.node
                                                                .excerpt
                                                        }
                                                        url={
                                                            relatedPost.node
                                                                .slug
                                                        }
                                                        title={
                                                            relatedPost.node
                                                                .title
                                                        }
                                                        img={
                                                            relatedPost.node
                                                                .feature_image
                                                        }
                                                    />
                                                </motion.div>
                                            </div>
                                        )}
                                    </InView>
                                );
                            })}
                        </div>
                    </Section>
                )}
            </div>
        </Layout>
    );
};
export default Post;
export const postQuery = graphql`
    query($slug: String!, $category: String!) {
        ghostPost(slug: { eq: $slug }) {
            title
            slug
            feature_image
            authors {
                name
            }
            created_at
            updated_at
            excerpt

            html
        }

        page_bg: file(relativePath: { eq: "page_bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        background: file(
            relativePath: { eq: "background_header_blurred.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        relatedPosts: allGhostPost(
            filter: { tags: { elemMatch: { name: { eq: $category } } } }
            limit: 4
            sort: { fields: published_at, order: DESC }
        ) {
            edges {
                node {
                    title
                    excerpt
                    slug
                    created_at
                    feature_image
                    tags {
                        name
                    }
                }
            }
        }
    }
`;
